import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import useTableStyles from '@styles/table/table'

export const ChartSkeleton = ({ width = '100%', height = '100%' }) => {
  return (
    <Box
      sx={{
        height,
        width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
    >
      <Skeleton width={'100%'} height={'100%'} variant="rectangular">
        <div />
      </Skeleton>
      <Box
        component="div"
        sx={{
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          mt: t => t.spacing(2),
          mx: 'auto'
        }}
      >
        <Skeleton />
      </Box>
    </Box>
  )
}

export const SelectSkeleton = () => {
  return (
    <Box component="div" sx={{ width: '100%', height: t => t.spacing(6) }}>
      <Skeleton
        height={'100%'}
        variant="rectangular"
        sx={{ borderRadius: t => t.spacing(1) }}
      />
    </Box>
  )
}

export const SelectMultilineSkeleton = () => {
  return (
    <Box component="div" sx={{ width: '100%', height: t => t.spacing(12) }}>
      <Skeleton
        height={'100%'}
        variant="rectangular"
        sx={{ borderRadius: t => t.spacing(1) }}
      />
    </Box>
  )
}

export const TextSkeleton = ({
  linesCount = 1,
  spacing = 0,
  sx = { width: '100%', mb: t => t.spacing(3) }
}) => {
  return (
    <Stack spacing={spacing} sx={sx} component="div">
      {[...new Array(linesCount)].map((e, i) => (
        <Skeleton key={i} />
      ))}
    </Stack>
  )
}

export const ChipSkeleton = ({ width = t => t.spacing(12) }) => {
  return (
    <Box component="div" sx={{ width: width, height: t => t.spacing(4) }}>
      <Skeleton
        width={'100%'}
        height={'100%'}
        variant="rectangular"
        sx={{ borderRadius: t => t.spacing(3) }}
      />
    </Box>
  )
}

export const ButtonSkeleton = ({
  count = 1,
  spacing = 2,
  borderRadius = t => t.spacing(1)
}) => {
  return (
    <Stack direction="row" spacing={spacing} sx={{ width: 'fit-content' }}>
      {[...new Array(count)].map((e, i) => (
        <Box
          key={i}
          component="div"
          sx={{ width: t => t.spacing(12), height: t => t.spacing(5) }}
        >
          <Skeleton
            height={'100%'}
            variant="rectangular"
            sx={{ borderRadius }}
          />
        </Box>
      ))}
    </Stack>
  )
}

export const CheckboxSkeleton = ({
  linesCount = 1,
  sx = { width: '100%' },
  spacing = 2
}) => {
  return (
    <Stack sx={sx} spacing={2}>
      {[...new Array(linesCount)].map((e, i) => (
        <Stack key={i} direction="row" spacing={spacing} width="100%">
          <span data-testid="span-50b74f16-acf2-4b0c-a744-6c92d066d794">
            <Skeleton
              variant="rectangular"
              sx={{ width: '20px', height: '20px' }}
            />
          </span>
          <Skeleton key={i} width="100%" />
        </Stack>
      ))}
    </Stack>
  )
}

export const TableCellSkeleton = ({ linesCount = 1, borderCell = false }) => {
  const classesTable = useTableStyles()
  return (
    <TableCell className={borderCell ? classesTable.borderCell : ''}>
      <Box
        component="div"
        sx={{
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        {[...new Array(linesCount)].map((e, i) => (
          <Skeleton key={i} />
        ))}
      </Box>
    </TableCell>
  )
}

export const TableRowSkeleton = ({ rowsCount = 1, cellsCount = 3 }) => {
  const classesTable = useTableStyles()
  return [...new Array(rowsCount)].map((e, i) => (
    <TableRow key={i} className={classesTable.row}>
      {[...new Array(cellsCount)].map((e, i) => (
        <TableCellSkeleton key={i + rowsCount} />
      ))}
    </TableRow>
  ))
}

export const TableSkeleton = ({ rowsCount = 1, cellsCount = 3 }) => {
  const classesTable = useTableStyles()
  return (
    <TableContainer className={classesTable.container}>
      <Table>
        <TableHead>
          <TableRow hover={false}>
            {[...new Array(cellsCount)].map((e, i) => (
              <TableCellSkeleton key={i + rowsCount} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...new Array(rowsCount)].map((e, i) => (
            <TableRow key={i} className={classesTable.row}>
              {[...new Array(cellsCount)].map((e, i) => (
                <TableCellSkeleton key={i + rowsCount} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const PDFSkeleton = () => {
  return (
    <Stack
      justifyContent="space-between"
      sx={{ height: '50vh' }}
      alignItems="center"
      mt={15}
    >
      <Box
        sx={{
          width: '30%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          mx: 'auto'
        }}
      >
        <Skeleton />
      </Box>
      <Box
        component="div"
        sx={{
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',

          mx: 'auto'
        }}
      >
        {[...Array(2)].map((el, index) => (
          <Skeleton sx={{ marginTop: '0.5rem' }} key={index} />
        ))}
      </Box>
      <Box
        component="div"
        sx={{
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',

          mx: 'auto'
        }}
      >
        {[...Array(4)].map((el, index) => (
          <Skeleton sx={{ marginTop: '0.5rem' }} key={index} />
        ))}
      </Box>
      <Box
        component="div"
        sx={{
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',

          mx: 'auto'
        }}
      >
        <Skeleton sx={{ marginTop: '0.8rem' }} />
        <Skeleton sx={{ marginTop: '0.8rem' }} />
      </Box>
    </Stack>
  )
}

export const MetricSkeleton = ({ withProgressBar = false }) => {
  return (
    <Grid
      container
      padding={3}
      sx={{
        height: '100%',
        backgroundColor: theme => theme.palette.primary.contrastText,
        borderRadius: theme => theme.spacing(3)
      }}
    >
      <Stack spacing={1} width="100%">
        <Typography
          variant="h6"
          sx={{ width: '60%' }}
          data-testid="Typography-041a137b-034a-4564-8143-7833fcbd121d"
        >
          <Skeleton />
        </Typography>
        {withProgressBar && (
          <Typography
            variant="caption"
            sx={{ width: '100%' }}
            data-testid="Typography-b172fdfc-b934-4ac3-9d77-96782d2059b5"
          >
            <Skeleton />
          </Typography>
        )}

        <Typography
          variant="caption"
          sx={{ width: '80%' }}
          data-testid="Typography-d431889b-e551-442b-bfe7-28f407f0249e"
        >
          <Skeleton />
        </Typography>
      </Stack>
    </Grid>
  )
}

export const GroupMetricsSkeleton = ({ number }) => {
  return (
    <>
      {[...Array(number)].map((_, index) => (
        <Grid key={index} item xs={12} md={12 / number}>
          <MetricSkeleton />
        </Grid>
      ))}
    </>
  )
}

GroupMetricsSkeleton.propTypes = {
  number: PropTypes.number
}
